import { isObject } from 'lodash'
import { requestWithPromise } from '../../utils'
import { formatTime } from '../../Views/shared/ShiftsPresetsManagerView/utils'
import { SHIFT_PRESETS } from './actionsType'
import { getAllShiftPresets } from '../../utils/api/shiftPreset'
import { HttpMethods } from '../../utils/apiHelper'

const { SET_SHIFT_PRESETS, UPDATE_SHIFT_PRESET, SET_SHIFT_PRESETS_LOADING, ADD_SHIFT_PRESET, REMOVE_SHIFT_PRESET } = SHIFT_PRESETS

const getShiftPresets = (state) => {
  return state.presets.presets
}

const getShiftPresetsLoading = (state) => {
  return state.presets.isLoading
}

const fetchInstitutionShiftsPresets = (institutionIds, user, useLoading = false) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_SHIFT_PRESETS_LOADING, payload: true })
    getAllShiftPresets(user, { institutions: institutionIds }).then(json => {
      if (json?.data) {
        dispatch({
          type: SET_SHIFT_PRESETS,
          payload: json.data
        })

        dispatch({ type: SET_SHIFT_PRESETS_LOADING, payload: false })
      }
    })
  }
}

const createOrUpdateShiftPreset = (preset, institutionId, sectorId, user) => {
  const body = {
    institution: institutionId,
    sector: sectorId,
    periodCode: preset.periodCode,
    startTime: `${formatTime(preset.startTime)}:00`,
    endTime: `${formatTime(preset.endTime)}:00`,
    pause: preset.pause,
    color: preset.color,
    shiftPause: isObject(preset.shiftPause) ? preset.shiftPause.id : preset.shiftPause
  }

  return async (dispatch, getState) => {
    requestWithPromise('/shift/presets', HttpMethods.POST, body, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch({
            type: preset.id ? UPDATE_SHIFT_PRESET : ADD_SHIFT_PRESET,
            payload: { institutionId, sectorId, preset: jsonResponse.data }
          })
        }
      })
  }
}

const deleteGlobalPresetFromSector = (preset, institutionId, sectorId, user) => {
  const body = {
    institution: institutionId,
    sector: sectorId,
    periodCode: preset.periodCode,
    startTime: `${formatTime(preset.startTime)}:00`,
    endTime: `${formatTime(preset.endTime)}:00`,
    pause: preset.pause,
    color: preset.color,
    deleteDefaultPreset: true
  }

  return async (dispatch, getState) => {
    requestWithPromise('/shift/presets', HttpMethods.POST, body, user
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch({
          type: REMOVE_SHIFT_PRESET,
          payload: { institutionId, sectorId, preset }
        })
      }
    })
  }
}

export {
  getShiftPresets,
  getShiftPresetsLoading,
  fetchInstitutionShiftsPresets,
  createOrUpdateShiftPreset,
  deleteGlobalPresetFromSector
}
